import i18n from 'i18next';
import i18nLanguageDetector from 'i18next-browser-languagedetector';
import i18nFetchBackend from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(i18nFetchBackend)
  .use(i18nLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr', 'nl', 'it'],
    load: 'currentOnly',
    lowerCaseLng: true,
    cleanCode: true,
    backend: {
      loadPath: `${
        process.env.REACT_APP_TRANSLATIONS_URL || process.env.REACT_APP_WEB_URL
      }/translations/{{lng}}.json`,
    },
    detection: {
      lookupQuerystring: 'lang',
      caches: [],
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    keySeparator: '/',
  });

export default i18n;
