import { gql, useQuery } from '@apollo/client';

import {
  COMMENT_DATA,
  COURSE_DATA,
  CHAPTER_DATA,
  FILE_DATA,
  GROUP_DATA,
  QUESTION_DATA,
  GAME_FULL_DATA,
  PARTICIPANT_DATA,
  PARTICIPANT_LIGHT_DATA,
  PROFILE_DATA,
  PUBLIC_COURSE_DATA,
  PUBLIC_CHAPTER_DATA,
  STUDY_STATS_DATA,
  TEST_SESSION_DATA,
} from 'gql/fragments';

export const QUERY_COURSES_GAR = gql`
  {
    coursesGAR {
      ...CourseData
    }
  }
  ${COURSE_DATA}
`;

export function useQueryCoursesGAR(options) {
  const { loading, data, ...props } = useQuery(QUERY_COURSES_GAR, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_PUBLIC_COURSE = gql`
  query ($courseId: ID!) {
    publicCourse(courseId: $courseId) {
      ...PublicCourseData
      questions {
        ...QuestionData
      }
      chapters {
        ...PublicChapterData
        questions {
          ...QuestionData
        }
      }
      author {
        ...ProfileData
      }
      files {
        ...FileData
      }
    }
  }
  ${PUBLIC_COURSE_DATA}
  ${PUBLIC_CHAPTER_DATA}
  ${QUESTION_DATA}
  ${PROFILE_DATA}
  ${FILE_DATA}
`;

export function useQueryPublicCourse(options) {
  const { loading, data, ...props } = useQuery(QUERY_PUBLIC_COURSE, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_PUBLIC_COURSES = gql`
  {
    publicCourses {
      ...PublicCourseData
      author {
        ...ProfileData
      }
    }
  }
  ${PUBLIC_COURSE_DATA}
  ${PROFILE_DATA}
`;

export function useQueryPublicCourses(options) {
  const { loading, data, ...props } = useQuery(QUERY_PUBLIC_COURSES, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_COURSES_ME = gql`
  {
    coursesMe {
      ...CourseData
      owner {
        ...ParticipantData
      }
    }
  }
  ${COURSE_DATA}
  ${PARTICIPANT_DATA}
`;

export function useQueryCoursesMe(options) {
  const { loading, data, ...props } = useQuery(QUERY_COURSES_ME, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_COURSES_ME_CHAPTERS = gql`
  {
    coursesMe {
      ...CourseData
      chapters {
        ...ChapterData
      }
    }
  }
  ${COURSE_DATA}
  ${CHAPTER_DATA}
`;

export function useQueryCoursesMeChapters(options) {
  const { loading, data, ...props } = useQuery(
    QUERY_COURSES_ME_CHAPTERS,
    options,
  );
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_COURSE = gql`
  query ($courseId: ID!) {
    course(courseId: $courseId) {
      ...CourseData
    }
  }
  ${COURSE_DATA}
`;

export function useQueryCourse(options) {
  const { loading, data, ...props } = useQuery(QUERY_COURSE, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_COURSE_PARTICIPANTS = gql`
  query ($courseId: ID!) {
    course(courseId: $courseId) {
      participants {
        ...ParticipantLightData
      }
    }
  }
  ${PARTICIPANT_LIGHT_DATA}
`;

export function useQueryCourseParticipants(options) {
  const { loading, data, ...props } = useQuery(
    QUERY_COURSE_PARTICIPANTS,
    options,
  );
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_COURSE_CHAPTERS = gql`
  query ($courseId: ID!) {
    course(courseId: $courseId) {
      ...CourseData
      chapters {
        ...ChapterData
      }
    }
  }
  ${COURSE_DATA}
  ${CHAPTER_DATA}
`;

export function useQueryCourseChapters(options) {
  const { loading, data, ...props } = useQuery(QUERY_COURSE_CHAPTERS, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_COURSE_CONTENT = gql`
  query ($courseId: ID!) {
    course(courseId: $courseId) {
      ...CourseData
      questions {
        ...QuestionData
        author {
          ...ProfileData
        }
        comments {
          ...CommentData
        }
      }
      questionsToApprove {
        ...QuestionData
        author {
          ...ProfileData
        }
      }
      chapters {
        ...ChapterData
        questions {
          ...QuestionData
          author {
            ...ProfileData
          }
          comments {
            ...CommentData
          }
        }
      }
      files {
        ...FileData
      }
    }
  }
  ${COURSE_DATA}
  ${CHAPTER_DATA}
  ${QUESTION_DATA}
  ${COMMENT_DATA}
  ${PROFILE_DATA}
  ${FILE_DATA}
`;

export function useQueryCourseContent(options) {
  const { loading, data, ...props } = useQuery(QUERY_COURSE_CONTENT, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_COURSE_GAMES = gql`
  query ($courseId: ID!) {
    course(courseId: $courseId) {
      ...CourseData
      games {
        ...GameFullData
      }
    }
  }
  ${COURSE_DATA}
  ${GAME_FULL_DATA}
`;

export function useQueryCourseGames(options) {
  const { loading, data, ...props } = useQuery(QUERY_COURSE_GAMES, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_COURSE_COLLABORATORS = gql`
  query ($courseId: ID!) {
    course(courseId: $courseId) {
      ...CourseData
      owner {
        ...ParticipantData
      }
      collaborators {
        ...ParticipantData
      }
    }
  }
  ${COURSE_DATA}
  ${PARTICIPANT_DATA}
`;

export function useQueryCourseCollaborators(options) {
  const { loading, data, ...props } = useQuery(
    QUERY_COURSE_COLLABORATORS,
    options,
  );
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_COURSE_STUDENTS = gql`
  query ($courseId: ID!) {
    course(courseId: $courseId) {
      ...CourseData
      groups {
        ...GroupData
        students {
          ...ParticipantData
        }
      }
      students {
        ...ParticipantData
      }
    }
  }
  ${COURSE_DATA}
  ${GROUP_DATA}
  ${PARTICIPANT_DATA}
`;

export function useQueryCourseStudents(options) {
  const { loading, data, ...props } = useQuery(QUERY_COURSE_STUDENTS, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_COURSE_PROGRESS = gql`
  query ($courseId: ID!) {
    course(courseId: $courseId) {
      ...CourseData
      chapters {
        ...ChapterData
        questions {
          ...QuestionData
          comments {
            ...CommentData
          }
          studyStats {
            ...StudyStatsData
          }
        }
      }
      questions {
        ...QuestionData
        comments {
          ...CommentData
        }
        studyStats {
          ...StudyStatsData
        }
      }
      files {
        ...FileData
      }
    }
  }
  ${COURSE_DATA}
  ${CHAPTER_DATA}
  ${QUESTION_DATA}
  ${COMMENT_DATA}
  ${STUDY_STATS_DATA}
  ${FILE_DATA}
`;

export function useQueryCourseProgress(options) {
  const { loading, data, ...props } = useQuery(QUERY_COURSE_PROGRESS, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_COURSE_TEST_SESSIONS = gql`
  query ($courseId: ID!) {
    course(courseId: $courseId) {
      ...CourseData
      testSessions {
        ...TestSessionData
      }
    }
  }
  ${COURSE_DATA}
  ${TEST_SESSION_DATA}
`;

export function useQueryCourseTestSessions(options) {
  const { loading, data, ...props } = useQuery(
    QUERY_COURSE_TEST_SESSIONS,
    options,
  );
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const QUERY_FULL_COURSE = gql`
  query ($courseId: ID!) {
    course(courseId: $courseId) {
      ...CourseData
      questions {
        ...QuestionData
        author {
          ...ProfileData
        }
        comments {
          ...CommentData
        }
      }
      questionsToApprove {
        ...QuestionData
        author {
          ...ProfileData
        }
      }
      chapters {
        ...ChapterData
        questions {
          ...QuestionData
          author {
            ...ProfileData
          }
          comments {
            ...CommentData
          }
        }
      }
      files {
        ...FileData
      }
      owner {
        ...ParticipantData
      }
      collaborators {
        ...ParticipantData
      }
      groups {
        ...GroupData
        students {
          ...ParticipantData
        }
      }
      students {
        ...ParticipantData
      }
      participants {
        ...ParticipantLightData
      }
    }
  }
  ${COURSE_DATA}
  ${CHAPTER_DATA}
  ${QUESTION_DATA}
  ${COMMENT_DATA}
  ${PROFILE_DATA}
  ${FILE_DATA}
  ${GROUP_DATA}
  ${PARTICIPANT_DATA}
  ${PARTICIPANT_LIGHT_DATA}
`;

export function useQueryFullCourse(options) {
  const { loading, data, ...props } = useQuery(QUERY_FULL_COURSE, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
